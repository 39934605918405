<template>
    <div class="authorization-page sign-in">

        <div class="form">
            <router-link :to="{name: 'Home'}">
                <img :src="require('@/assets/img/logo.png')" class="logo" alt="EURECBROKER">
            </router-link>

            <h1>{{ $t("Sign In") }}</h1>

            <form @submit.prevent="login" class="login-form">
                <div class="social-signup">
                    <router-link :to="{ name: 'SocialAuthorizationRedirect', params: { network: 'facebook' } }">
                        <button class="btn btn-social-auth" type="button">
                            <div class="img-wrapper">
                                <img src="@/assets/icon/facebook.png" alt="facebook" />
                            </div>
                            <span>{{ $t('Log in with facebook') }}</span>
                        </button>
                    </router-link>

                    <router-link :to="{ name: 'SocialAuthorizationRedirect', params: { network: 'google' } }">
                        <button class="btn btn-social-auth mid-btn" type="button">
                            <div class="img-wrapper">
                                <img src="@/assets/icon/google.png" alt="google" />
                            </div>
                            <span>{{ $t('Log in with Google') }}</span>
                        </button>
                    </router-link>

                    <router-link :to="{ name: 'SocialAuthorizationRedirect', params: { network: 'linkedin' } }">
                        <button class="btn btn-social-auth" type="button">
                            <div class="img-wrapper">
                                <img src="@/assets/icon/linkedin.png" alt="linkedin" />
                            </div>
                            <span>{{ $t('Log in with Linked In') }}</span>
                        </button>
                    </router-link>
                </div>
                <div class="sign-in-alternative">
                    <hr />
                    <span>{{ $t('or') }}</span>
                    <hr />
                </div>

                <div class="form-group">
                    <input
                        type="text"
                        id="email"
                        name="email"
                        :placeholder="$t('Email')"
                        v-model="credentials.email"
                        :class="['form-control', {'has-error': errors['email']}]"
                    >
                    <FormErrors v-if="errors['email']" :errors="errors['email']"  />

                </div>
                <div class="form-group">
                    <input
                        type="password"
                        id="password"
                        name="password"
                        :placeholder="$t('Password')"
                        v-model="credentials.password"
                        class="form-control"
                    >

                </div>

                <router-link :to="{name: 'ForgotPassword'}" class="forgot">
                    <span>{{ $t('Forgot your password?') }}</span>
                </router-link>

                <button type="submit" class="btn btn-primary w-100" :value="$t('Login')" :disabled="disabled">{{ $t("Continue") }}</button>

                <p class="already-an-account">{{ $t('Don\'t have an account yet?') }} <router-link :to="{ name: 'Register' }">{{ $t('Register') }}</router-link></p>
            </form>

        </div>
        <div class="side">
            <img :src="require('@/assets/img/background-authorization-page.png')" :alt="$t('RecBroker authorization image')" />
            <div class="circle black"></div>
            <div class="circle blue"></div>
        </div>
    </div>
</template>

<script>
import FormErrors from "@/components/widgets/FormErrors";

export default {
    name: "Login",
    components: {
        FormErrors
    },
    data() {
        return {
            credentials: {
                email: '',
                password: '',
            },
        }
    },
    beforeMount() {
        document.body.classList.add('modal-open')
        // document.body.style = "padding-right: 17px;"
    },
    unmounted() {
        document.body.classList.remove('modal-open')
        // document.body.style = null
    },
    emits: ['hideLoginForm'],
    methods: {
        login() {
            this.disabled = true
            this.errors = []
            this.$store.dispatch('login', this.credentials)
                .then(() => {
                    if (typeof this.$route.redirectedFrom !== "undefined") {
                        console.log('redirecting to... ' + this.$route.redirectedFrom.fullPath)
                        this.$router.push(this.$route.redirectedFrom.fullPath)
                    } else {
                        this.$router.push({name: 'Home'})
                    }
                })
                .catch(error => this.errors = error.response.data.errors || [])
                .finally(() => this.disabled = false)
        },
        hideLoginForm() {
            this.$router.push({name: 'Home'})
        }
    },
}
</script>

<style scoped>

</style>